import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/SEO"

export default function AboutPage() {
  return (
    <>
      <SEO title="About Me" />
      <div>coming soon</div>
    </>
  )
}
